import {FlowAPI, EditorReadyFn, FlowEditorSDK} from '@wix/yoshi-flow-editor'

import {
  APP_PAGES,
  APP_DEF_ID,
  AppPage,
  APP_PAGE_TO_TITLE_KEY,
  PARENT_APP_DEF_ID,
  SPECS,
} from '../utils/constants'

const SDK_TOKEN = ''

const getAppPagesOnStage = async (editorSDK: FlowEditorSDK) => {
  const [tpaData, allSitePages] = await Promise.all([
    editorSDK.tpa.app.getDataByAppDefId(SDK_TOKEN, APP_DEF_ID),
    editorSDK.pages.data.getAll(SDK_TOKEN),
  ])

  return allSitePages.filter((page) => {
    return (
      page.tpaApplicationId === tpaData.applicationId &&
      APP_PAGES.includes((page.tpaPageId as AppPage) ?? '')
    )
  })
}

const setupPages = async (editorSDK: FlowEditorSDK, flowAPI: FlowAPI) => {
  const appPages = await getAppPagesOnStage(editorSDK)
  const isProvisionRestaurantsAppExperimentEnabled = flowAPI.experiments.enabled(
    SPECS.provisionParentAppAppId,
  )
  const managingAppDefId = isProvisionRestaurantsAppExperimentEnabled
    ? PARENT_APP_DEF_ID
    : APP_DEF_ID
  const promises = appPages.map((page) => {
    return editorSDK.document.pages.data.update(SDK_TOKEN, {
      pageRef: {
        id: page.id ?? '',
        type: 'DESKTOP',
      },
      data: {
        title: flowAPI.translations.t(APP_PAGE_TO_TITLE_KEY[page.tpaPageId as AppPage]),
        managingAppDefId,
        ...(page.tpaPageId === AppPage.ReservationConfirmation ? {pageUriSEO: 'reservation'} : {}),
      },
    })
  })

  return Promise.all(promises)
}

const updatePages = async (editorSDK: FlowEditorSDK, _: FlowAPI) => {
  const appPages = await getAppPagesOnStage(editorSDK)

  const promises = appPages.map((page) => {
    return editorSDK.document.pages.data.update(SDK_TOKEN, {
      pageRef: {
        id: page.id ?? '',
        type: 'DESKTOP',
      },
      data: {
        ...(page.tpaPageId !== AppPage.Reservations ? {indexable: false} : {}),
      },
    })
  })

  return Promise.all(promises)
}

const updateManagingAppDefId = async (editorSDK: FlowEditorSDK, _: FlowAPI) => {
  const appPages = await getAppPagesOnStage(editorSDK)

  const promises = appPages.map((page) => {
    return editorSDK.document.pages.data.update(SDK_TOKEN, {
      pageRef: {
        id: page.id ?? '',
        type: 'DESKTOP',
      },
      data: {managingAppDefId: PARENT_APP_DEF_ID},
    })
  })

  return Promise.all(promises)
}

const setTableReservationsPagesState = async (editorSDK: FlowEditorSDK) => {
  const applicationPages = await editorSDK.document.pages.data.getAll(SDK_TOKEN)
  const tableReservationsPages = applicationPages.filter(({tpaPageId}) =>
    Object.values<string>(AppPage).includes(tpaPageId!),
  )
  const promises = tableReservationsPages.map((pageData) => {
    return editorSDK.document.pages.setState(SDK_TOKEN, {
      // @ts-expect-error
      state: {
        [pageData.tpaPageId!]: [{id: pageData.id!}],
      },
    })
  })

  return Promise.all(promises)
}

export const editorReady: EditorReadyFn = async (editorSDK, appDefId, platformOptions, flowAPI) => {
  const isProvisionRestaurantsAppExperimentEnabled = flowAPI.experiments.enabled(
    SPECS.provisionParentAppAppId,
  )
  const isParentAppInstalled = await editorSDK.application.isApplicationInstalled('', {
    appDefinitionId: PARENT_APP_DEF_ID,
  })
  if (!isParentAppInstalled && isProvisionRestaurantsAppExperimentEnabled) {
    await editorSDK.document.application.add(SDK_TOKEN, {
      appDefinitionId: PARENT_APP_DEF_ID,
    })
    await updateManagingAppDefId(editorSDK, flowAPI)
  }

  if (platformOptions.firstInstall) {
    await setupPages(editorSDK, flowAPI)
  }

  await updatePages(editorSDK, flowAPI)

  await setTableReservationsPagesState(editorSDK)
}
